import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';  
import configureStore from './store/store';

const store = configureStore();

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
          <App />
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

/* import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import authentication from './context/b2cAuthConfig'; 



authentication.initialize();

authentication.run(()=>{
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
  //serviceWorker.unregister();
}); */
