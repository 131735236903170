import React from 'react'
import axios from 'axios';
import config from '../context/config'

//const ARTICLE_API_BASE_URL = "https://aedevapigw-internal.corp.al-futtaim.com/dev/logistics/article/v1/articles?";
const ARTICLE_API_BASE_URL = config.getLogisticsAPIUrl();

class articleService extends React.Component {

    async getArticle(siteCode, externalCustomerName) {

        //console.log(ARTICLE_API_BASE_URL + 'siteCode=' + siteCode + '&externalCustomerName=' + externalCustomerName);

        return await axios.get(ARTICLE_API_BASE_URL + 'articlecatalogue?siteCode=' + siteCode + '&externalCustomerName=' + externalCustomerName
            , {
                /*  headers: {
                     'Connection': 'keep-alive',
                     withCredentials: false,
                 } */
            })
            .then((res) => {
                //console.log('articleService');
                //console.log(res.data.articles)
                return res.data;
            })
            .catch((error) => {
                console.error(error)
            })
    }

    userSessionLogout() {
        return config.getPostLogoutRedirectUri();
    }

}

export default new articleService()

