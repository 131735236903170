import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import userService from '../../../apiLogistics/userService'
import {
    Table, FormControl, Button
} from "react-bootstrap";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Select, { components } from 'react-select';

const UserList = ({ userState }) => {
    const [allUserData, setAllUserData] = useState();
    const [filteredUserData, setFilteredUserData] = useState();
    const [userBrands, setUserBrands] = useState();

    useEffect(() => {
        //debugger;
        userService.getAllUserDetails().then(function (result) {
            console.log('getAllUserDetails');
            console.log(userState);
            setAllUserData(result);
            setFilteredUserData(result);
        });

    }, []);

    useEffect(() => {
        let sortUserList = [];
        let res01 = allUserData?.map(users => {
            let hasSite = false;
            let res1 = users.sites?.map(site => {
                if (site.value == userState.catalogue.selectedSite)
                    hasSite = true;
            })

            if (hasSite)
                sortUserList = [...sortUserList, users];
        });
        setFilteredUserData(sortUserList);

    }, [userState.catalogue?.selectedSite]);

    useEffect(() => {
        let sortUserList = [];
        let res01 = allUserData?.map(users => {
            let hasSite = false;
            let res1 = users.sites?.map(site => {
                if (site.value == userState.catalogue.selectedSite) {
                    let res2 = site.children?.map(party => {
                        if (party.value == userState.catalogue.selectedParty)
                            hasSite = true;
                    })
                }
            })

            if (hasSite)
                sortUserList = [...sortUserList, users];
        });
        setFilteredUserData(sortUserList);

    }, [userState.catalogue?.selectedParty]);

    const colourStyles = {
        control: base => ({
            ...base,
            color: "black"
        })
    };

    function handleChange(event) {

        let sortUserList = [];
        let res01 = allUserData?.map(users => {
            let hasSite = false;
            let res1 = users.sites?.map(site => {
                if (site.value == userState.catalogue.selectedSite) {
                    let res2 = site.children?.map(party => {
                        if (party.value == userState.catalogue.selectedParty) {
                            let res3 = party.children?.map(brand => {
                                if (brand.value == event.label) {
                                    hasSite = true;
                                }
                            })

                        }
                    })
                }
            })

            if (hasSite)
                sortUserList = [...sortUserList, users];
        });
        setFilteredUserData(sortUserList);

    }

    return (
        <div className="row divContent">
            <div className="col-md-12">
                <div className={userState.article.masterBrand?.length ? "hidden" : "col-md-4"}>
                </div>
                <div className={userState.article.masterBrand?.length ? "col-md-4" : "hidden"}>
                    <Select styles={colourStyles} onChange={handleChange} options={userState.article.masterBrand} placeholder="Select Brand" />
                </div>
                <Table striped bordered hover className="table sticky-table">
                    <thead className="stickyHead">
                        <tr>
                            <th>User Email</th>
                            <th>Full Name</th>
                            <th>Status</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUserData?.map((l, i) =>
                            <tr key={i}>
                                <td>
                                    <Link
                                        to={{
                                            pathname: "/userManagement",
                                            userManagementProps: l
                                        }}>
                                        {l.id}
                                    </Link>
                                </td>
                                <td>{l.fullname}</td>
                                <td>{l.status}</td>
                                <td>{l.type}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    )

}
const mapStateToProps = state => {
    return { userState: state }
}

export default connect(mapStateToProps)(UserList)
//export default UserList;