import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/* const Employees = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
]; */

export default class ExportExcel extends React.Component {

    constructor() {
        super();
        console.log(this.props);
    }

    render() {
        console.log(this.props);
        console.log(JSON.stringify(this.props.data));
        return (
            
            <ExcelFile filename={"ArticleStockReport"} element={<button className="btn btn-primary">Download Data</button>}>
                <ExcelSheet dataSet={this.props.data} name="Article Stock">
{/*
                    <ExcelColumn label="Warehouse Number" value="siteCode"  />
                    <ExcelColumn label="Party Ent. to Dis." value="externalCustomerName" />
                    <ExcelColumn label="Brand" value="brand" />
                    <ExcelColumn label="Article" value="articleCode" />
                    <ExcelColumn label="Barcode" value="articleBarcode" />
                    <ExcelColumn label="Article Description" value="articleDescription" />
                    <ExcelColumn label="Unit Of Measure" value="unitOfMeasure" />
                    <ExcelColumn label="In stock" value="totalQuantity" />
                    <ExcelColumn label="Available" value="scannedQuantity" />
                    <ExcelColumn label="Under Picking" value="underPicking" />
                    <ExcelColumn label="Under Putaway" value="underPutaway" />
                    <ExcelColumn label="Stock Status" value="stockType" />
                    <ExcelColumn label="Stock Type Desc" value="stockTypeDescription" />
                    <ExcelColumn label="Production Date" value="productionDate" />
                    <ExcelColumn label="Expiry Date" value="expiryDate" />
                    <ExcelColumn label="Remaining Shelf Life" value="remainingSelfLife" />
                    <ExcelColumn label="Per Carton" value="perCarton" />
                    <ExcelColumn label="Volume" value="volume"  />
                    <ExcelColumn label="Volume Unit" value="volumeUnit" />
                    <ExcelColumn label="Vintage" value="vintage" />
                    <ExcelColumn label="Batch #" value="articleBatchNumber" />
                    <ExcelColumn label="Lot #" value="lotNumber" />
                    <ExcelColumn label="Serial #" value="serialNumber" />
                    <ExcelColumn label="FIRS Number" value="foodInspectionReportNumber" />
                    <ExcelColumn label="Country of Origin" value="countryOfOrigin" />
                    <ExcelColumn label="GRN Date" value="goodsReceiptDate" />
                    <ExcelColumn label="Store" value="store" />

                     <ExcelColumn label="Name" value="name" />
                    <ExcelColumn label="Wallet Money" value="amount" />
                    <ExcelColumn label="Gender" value="sex" />
                    <ExcelColumn label="Marital Status" value={(col) => col.is_married ? "Married" : "Single"} /> */}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

//export default new ExportExcel()