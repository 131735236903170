import { Types, HomeTypes } from '../constants/ActionType';


const initialState = {
  article: [],
  catalogue: [],
  filterCatalogue: [],
  masterkeys: [],
  masterBrand: [],
  authToken: '',
  selectedSite: '',
  selectedParty: '',
  selectedBrand: '',
  selectedText: '',
  loginSuccessful: false
}

const reducer = (state = initialState, action) => {
  //debugger;
  switch (action.type) {
    case HomeTypes.CATALOGUE:
      return {
        ...state,
        catalogue: action.payload.catalogueData,
        filterCatalogue: action.payload.catalogueData,
        formSubmitted: false // after update user formsubmition reset
      }
    case HomeTypes.ARTICLEFILTER:
      return {
        ...state,
        filterCatalogue: action.payload.data,
        formSubmitted: false // after update user formsubmition reset
      }
    case HomeTypes.MASTERKEY:
      return {
        ...state,
        masterkeys: action.payload.data,
        formSubmitted: false // after update user formsubmition reset
      }
    case HomeTypes.SELECTEDSITE:
      return {
        ...state,
        selectedSite: action.payload.selectedSite,
        formSubmitted: false // after update user formsubmition reset
      }
    case HomeTypes.SELECTEDPARTY:
      return {
        ...state,
        selectedParty: action.payload.selectedParty,
        formSubmitted: false // after update user formsubmition reset
      }
    case HomeTypes.SELECTEDBRAND:
      return {
        ...state,
        selectedBrand: action.payload.selectedBrand,
        formSubmitted: false // after update user formsubmition reset
      }
    case HomeTypes.SEARCHTEXT:
      return {
        ...state,
        selectedText: action.payload.selectedText,
        formSubmitted: false // after update user formsubmition reset
      }
    case HomeTypes.MASTERBRAND:
      return {
        ...state,
        masterBrand: action.payload.data,
        formSubmitted: false // after update user formsubmition reset
      }
    default:
      return state;
  }
}

export default reducer;