export const Types = {
  LOGIN: 'LOGIN',
  DASHBOARD: 'DASHBOARD'
}

export const HomeTypes = {
  HOME: 'HOME',
  CATALOGUE: 'CATALOGUE',
  SELECTEDSITE: 'SELECTEDSITE',
  SELECTEDPARTY: 'SELECTEDPARTY',
  SELECTEDBRAND: 'SELECTEDBRAND',
  SEARCHTEXT: 'SEARCHTEXT',
  BRANDFILTER: 'BRANDFILTER',
  ARTICLEFILTER: 'BRANDFILTER',
  MASTERKEY: 'MASTERKEY'
}

export const viewarticleTypes = {
  LANDING: 'LANDING',
  SELECTEDSITE: 'SELECTEDSITE',
  SELECTEDPARTY: 'SELECTEDPARTY',
  ARTICLEOPTIONS: 'ARTICLEOPTIONS',
  TEXTFILTER: "TEXTFILTER",
  MASTERBRAND:'MASTERBRAND'
}

export const ProfileTypes = {
  USERPROFILE: 'USERPROFILE',
  UPDATEUSERPROFILE: 'UPDATEUSERPROFILE',
  USERPROFILEDATA: 'USERPROFILEDATA'
}