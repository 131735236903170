import React, { useState, useReducer, useEffect } from 'react'
import { connect, useSelector, useDispatch, useRef } from 'react-redux';
import {
    Table, FormControl, Button
} from "react-bootstrap";
import Select from 'react-select';
import { ArtclActionCreators } from '../../../actions/articleAction'
import articleService from '../../../apiLogistics/articleService'

function Viewcatalogue({ currentState }) {

    const dispatch = useDispatch();
    const [state, setState] = useState({ currentState });
    let searchInput = React.createRef();

    useEffect(() => {
        articleService.getArticle(currentState.catalogue.selectedSite, currentState.catalogue?.selectedParty).then(function (result) {
            dispatch(ArtclActionCreators.landing([]));
            dispatch(ArtclActionCreators.artclOptions([]));
            if (result) {
                dispatch(ArtclActionCreators.landing(result));
                const ArticleData = result.map((data, i) => { return { value: data.articleNumber, label: data.articleShortDescription } });
                dispatch(ArtclActionCreators.artclOptions(ArticleData));
            }
        });
    }, [currentState.catalogue?.selectedParty]);

    let onOnclickHandler = (e) => {
        console.log('Article 1 : ');
        console.log(currentState);

        if (searchInput.current.value === "") {
            dispatch(ArtclActionCreators.filterArtilce(currentState.article.article));
        }
        else {
            dispatch(ArtclActionCreators.filterArtilce(currentState.article.article.filter(
                (data) => {
                    return data.articleNumber.search(searchInput.current.value) != -1;
                })));
        }

    };

    return (
        <><div className="row searchControl">
            <div className="col-md-3">
                <input ref={searchInput} className="form-control" type="search" placeholder="Search" aria-label="Search" />
            </div>
            <div className="col-md-2">
                <button className="btn btn-primary" type="submit" onClick={onOnclickHandler}>Search</button>
            </div>
            {/* <div className="col-md-2 float-right">
                <button className="btn btn-primary" type="submit">Export Data</button>
            </div> */}
            <div className="col-md-8"></div>
        </div>
            <br></br>
            <div className="row divContent">
                <div className="col-md-12">

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Article Number</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentState.article.filterArticle?.map((l, i) =>

                                <tr key={i}>
                                    <td>{l.articleNumber}</td>
                                    <td>{l.articleShortDescription}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return { currentState: state }
}

export default connect(mapStateToProps)(Viewcatalogue);