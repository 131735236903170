import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import CatalogueService from '../../../apiLogistics/CatalogueService'
import userService from '../../../apiLogistics/userService'
import Container from '../../../utils/DropdownContainer';
import { useLocation } from "react-router-dom";

import './style.css'

const UserManagement = props => {

  const location = useLocation();
  const [existingUser, setExistingUser] = useState(false);
  const [userIsActive, setUserIsActive] = useState(false);
  const state = location.userManagementProps;


  const [user, setUser] = useState({
    id: state ? state.id : '',
    fullname: state ? state.fullname : '',
    sites: state ? state.sites : '',
    status: state ? state.status : ''
  });

  const [errorMsg, setErrorMsg] = useState('');
  const { id, fullname, sites, status } = user;

  const [masterKeys, setMasterKeys] = useState();
  const [userStatus, setUserStatus] = React.useState("");
  const [parties, setParties] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectMasterKey, setSelectMasterKey] = useState([]);
  const [userSelectedMasterData, setUserSelectedMasterData] = useState([]);
  const [message, setMessage] = useState();



  useEffect(() => {

    if (!masterKeys) {
      CatalogueService.articleMasterKeys().then(function (result) {
        if (result) {
          setMasterKeys(result[0])
          userIntialSetup(result[0].items);
        }
      });
    }

  }, []);

  const userIntialSetup = (res) => {


    let SelectSitesMaster = res.map((site, i) => {
      return {
        _id: "rdts1-" + i,
        label: site.label,
        value: site.value,
        children: site.parties?.map((party, j) => {
          return {
            _id: "rdts1-" + i + "-" + j,
            label: party.label,
            value: party.value,
            children: party.brands?.map((brand, k) => {
              return {
                _id: "rdts1-" + i + "-" + j + "-" + k,
                label: brand.label,
                value: brand.value
              }

            })
          }

        })
      }
    });

    if (state) {
      let siteList = [];
      setExistingUser(true);
      if (state.status == "Active")
      {
        setUserStatus("Active");
        setUserIsActive(true);
      }
      
      let resss = SelectSitesMaster?.map(site => {
        let siteVal = site._id;
        let site1 = state.sitesNodes?.map(items1 => {
          let itemVal1 = items1;
          if (siteVal == itemVal1) {
            site = { ...site, checked: true }
          }
          else {
            let site1 = site.children?.map((party, i) => {
              let partyVal = party._id;
              if (itemVal1 == partyVal) {
                party = { ...party, checked: true };
                site.children[i] = party;
              }
              else {
                let brand1 = party.children?.map((brand, j) => {
                  let brandVal = brand._id;
                  if (brandVal == itemVal1) {
                    brand = { ...brand, checked: true }
                    party.children[j] = brand;
                  }
                });
                site.children[i] = party;
              }
            })

          }
        });
        siteList = [...siteList, site]
      });
      setSelectMasterKey(siteList);
      setUserSelectedMasterData({ sites: res.sites, sitesNodes: state.sitesNodes })
    }
    else {
      setSelectMasterKey(SelectSitesMaster);
    }
    console.log(selectMasterKey);

  }


  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const values = [id, fullname, userSelectedMasterData];
    let errorMsg = '';

    const allFieldsFilled = values.every((field) => {
      const value = `${field}`.trim();
      return value !== '' && value !== '0';
    });

    if (allFieldsFilled) {
      const userData = {
        id,
        fullname,
        status: userStatus,
        token: "",
        sessionExpiry: "",
        type: "user",
        ...userSelectedMasterData,
      };
      console.log('userData');
      console.log(userData);
      userService.createNewUser(JSON.stringify(userData));
      setMessage("User data updated successfully.");
    } else {
      errorMsg = 'Please fill out all the fields.';
    }
    setErrorMsg(errorMsg);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'fullname1':
        if (value === '' || parseInt(value) === +value) {
          setUser((prevState) => ({
            ...prevState,
            [name]: value
          }));
        }
        break;
      case 'sites':
        if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
          setUser((prevState) => ({
            ...prevState,
            [name]: value
          }));
        }
        break;
      default:
        setUser((prevState) => ({
          ...prevState,
          [name]: value
        }));
    }
  };

  function handleChange(event) {

    setParties(masterKeys.items.filter(option =>
      option.label === event.label)[0].parties);

  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "black"
      };
    }
  };

  function partyHandleChange(event) {
    setBrands(parties?.filter(option =>
      option.label === event.label)[0].brands);
  };

  const onChange = (currentNode, selectedNodes) => {

    console.log(selectedNodes);

    let siteList = [];

    selectMasterKey.map(site => {
      let siteVal = site._id;
      let partyList = [];
      let brandList = [];

      let partyName = null;

      selectedNodes.map(items1 => {
        let itemVal1 = items1._id;
        if (siteVal == itemVal1) {
          siteList = [...siteList, site]
        }
        else {

          let site1 = site.children?.map(party => {
            partyName = party;
            let partyVal = party._id;
            if (itemVal1 == partyVal) {
              partyList = [...partyList, party]
            }
            else {
              let brand1 = party.children?.map(brand => {
                let brandVal = brand._id;
                if (brandVal == itemVal1)
                  brandList = [...brandList, brand]
              });
            }
          })
        }
      });
      if (brandList && brandList.length)
        partyList = [...partyList,
        {
          label: partyName.label,
          value: partyName.value,
          _id: partyName._id,
          children: brandList
        }
        ]
      if (partyList && partyList.length)
        siteList = [...siteList,
        {
          label: site.label,
          value: site.value,
          _id: site._id,
          children: partyList
        }]
    });
    setUserSelectedMasterData({ sites: siteList, sitesNodes: selectedNodes.map(item => { return item._id }) });
    //console.log(siteList);
  }

  const onAction = (node, action) => {
    console.log('onAction::', action, node)
  }
  const onNodeToggle = currentNode => {
    console.log('onNodeToggle::', currentNode)
  }

  const activeHandleChange = (e) => {

    if (e.target.checked) {
      setUserStatus("Active");
      setUserIsActive(true);
    }
    else {
      setUserStatus("InActive");
      setUserIsActive(false);
    }
  };

  return (
    <div className="h-auto d-inline-block mw-100">
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      <Form onSubmit={handleOnSubmit}>
        <Form.Group controlId="fullname">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            className="input-control"
            type="text"
            name="fullname"
            value={fullname}
            placeholder="Enter name of user"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="id">
          <Form.Label>Email Id</Form.Label>
          <Form.Control
            className="input-control"
            type="text"
            name="id"
            value={id}
            placeholder="Enter User Email Id"
            onChange={handleInputChange}
            disabled={existingUser}
          />
        </Form.Group>
        {/* <Form.Group controlId="quantity">
          <Form.Label>sites</Form.Label>
          <Select name="comboSite" onChange={handleChange} options={masterKeys?.items} styles={colourStyles} placeholder="Select Site" />
        </Form.Group>
        <Form.Group controlId="quantity">
          <Form.Label>Party</Form.Label>
          <Select name="comboParty" onChange={partyHandleChange} options={parties} styles={colourStyles} placeholder="Select Party" />
        </Form.Group>
        <Form.Group controlId="quantity">
          <Form.Label>Brand</Form.Label>
          <Select name="comboParty" options={brands} styles={colourStyles} placeholder="Select Brand" />
        </Form.Group> */}
        <Form.Group controlId="access">
          <Form.Label>Aceess</Form.Label>
          <Container data={selectMasterKey}{...["rdts1-0"]} onChange={onChange} />
        </Form.Group>
        <Form.Group controlId="userStatus">
          <Form.Label>
            <input
              type="checkbox"
              checked={userIsActive}
              onChange={activeHandleChange}
            />
            Is Active
          </Form.Label>
        </Form.Group>
        <br></br>
        <Button variant="primary" type="submit" className="submit-btn">
          SAVE
        </Button>
        <br></br>{message}<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      </Form>
    </div>
  );

}

export default UserManagement;