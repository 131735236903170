const config = require('./config.json');

function getEnvironmentConfig() {
    const env = "azure";//(process.env.NODE_ENV != null && process.env.NODE_ENV != undefined) ? process.env.NODE_ENV : 'development';
    return config[env];  
  }

  function getLogisticsAPIUrl() {
    return process.env.REACT_APP_logisticsAPIUrl || getEnvironmentConfig()['logisticsAPIUrl'];
  }

  function getSignUpSignInPolicy() {
    return process.env.REACT_APP_signUpSignInPolicy || getEnvironmentConfig()['signUpSignInPolicy'];
  }

  function getAuthority() {
    console.log("test");
    console.log(process.env.REACT_APP_authority);
    console.log(getEnvironmentConfig()['authority']);
    return process.env.REACT_APP_authority || getEnvironmentConfig()['authority'];
  }
  function getSignupAuthority() {
    return process.env.authority || getEnvironmentConfig()['signupAuthority'];
  }

  function getTenantUrl() {
    return process.env.REACT_APP_tenantUrl || getEnvironmentConfig()['tenantUrl'];
  }

  function getAuthorityDomain() {
    return process.env.REACT_APP_authorityDomain || getEnvironmentConfig()['authorityDomain'];
  }

  function getClientId() {
    return process.env.REACT_APP_clientId || getEnvironmentConfig()['clientId'];
  }
  
  function getRedirectUri() {
    return process.env.REACT_APP_redirectUri || getEnvironmentConfig()['redirectUri'];
  }

  function getPostLogoutRedirectUri() {
    return process.env.REACT_APP_postLogoutRedirectUri || getEnvironmentConfig()['postLogoutRedirectUri'];
  }

  function getNavigateToLoginRequestUrl() {
    return process.env.REACT_APP_navigateToLoginRequestUrl || getEnvironmentConfig()['navigateToLoginRequestUrl'];
  }
  
  function getScopes() {
    return getEnvironmentConfig()['scopes'];
  }
  
  function getADCache() {
     console.log("test");
    console.log(process.env.REACT_APP_ADCache);
    console.log(getEnvironmentConfig()['ADCache']);
    return getEnvironmentConfig()['ADCache'];
  }

  function getAdminAuthority() {
    return process.env.REACT_APP_adminAuthority || getEnvironmentConfig()['adminAuthority'];
  }

  function userSessionLogout() {
    return process.env.userSessionLogout || getEnvironmentConfig()['userSessionLogout'];
  }
  
  module.exports = {
    getLogisticsAPIUrl,
    getEnvironmentConfig,
    getADCache,
    getScopes,
    getNavigateToLoginRequestUrl,
    getPostLogoutRedirectUri,
    getRedirectUri,
    getClientId,
    getAuthorityDomain,
    getTenantUrl,
    getAuthority,
    getSignUpSignInPolicy,
    getAdminAuthority,
    userSessionLogout,
    getSignupAuthority
  }