import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import {
    Table, FormControl, Button
} from "react-bootstrap";
import Select, { components } from 'react-select';
import { default as ReactSelect } from "react-select";
import { ActionCreators } from '../../../actions/homeAction'
import ExportExcel from '../../../utils/ExportExcel'
import CatalogueService from '../../../apiLogistics/CatalogueService'
import articleService from '../../../apiLogistics/articleService'
import { ArtclActionCreators } from '../../../actions/articleAction'
import './style.css'
import { set } from 'lodash';

const Option = (homeState) => {
    return (
        <div>
            <components.Option {...homeState}>
                <input type="checkbox" checked={homeState.isSelected} onChange={() => null} /> <label>{homeState.value}</label>
            </components.Option>
        </div>
    );
};

const MultiValue = ({ index, getValue, ...homeState }) => {
    const maxToShow = 3;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...homeState} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};

const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};





function Home({ homeState }) {

    const dispatch = useDispatch();
    const [state, setState] = useState({ homeState });
    const [articleOptions, setArticleOptions] = useState([]);
    const [sumInStock, setSumInStock] = useState([]);
    const [sumAvailable, setSumAvailable] = useState([]);
    const [sumUnderPicking, setSumUnderPicking] = useState([]);
    const [sumUnderPutAway, setSumUnderPutAway] = useState([]);
    const [storeMaster, setStoreMaster] = useState([]);
    const [selectedStore, setSelectedStore] = useState([]);

    const colourStyles = {
        control: base => ({
            ...base,
            color: "black"
        })
    };

    useEffect(() => {
        setSelectedStore([]);
        dispatch(ActionCreators.filterBrand([]));
        CatalogueService.getCatalogue(homeState.catalogue.selectedSite, homeState.catalogue?.selectedParty).then(function (result) {
            dispatch(ActionCreators.catalogue(null))
            if (result) {
                if (homeState.article.masterBrand) {
                    let yFilter = [];
                    yFilter = homeState.article.masterBrand?.map(item => { return item.value; });
                    result = result.filter(item => yFilter?.includes(item.brand));
                }

                if (homeState.catalogue?.selectedParty == "AFIKEAFB") {
                    //setStoreMaster(result.map((data,i) => { return { value: data.store, label: data.store } }))
                    const storeList = result.map((data, i) => { return { value: data.store, label: data.store } });
                    //const uniqueStoreList = storeList.filter((value, label, array) => array.indexOf(label) === value);
                    const uniqueStoreList = storeList.map(JSON.stringify).filter((e, i, a) => i === a.indexOf(e)).map(JSON.parse);
                    setStoreMaster([{ value: 'All', label: 'All' }, ...uniqueStoreList]);
                }

                dispatch(ActionCreators.catalogue(result));
                setSumInStock(result?.reduce((sumInStock, currentItem) => sumInStock = sumInStock + parseFloat(currentItem.totalQuantity), 0));
                setSumAvailable(result?.reduce((sumAvailable, currentItem) => sumAvailable = sumAvailable + parseFloat(currentItem.scannedQuantity), 0));
                setSumUnderPicking(result?.reduce((sumUnderPicking, currentItem) => sumUnderPicking = sumUnderPicking + parseFloat(currentItem.underPicking), 0));
                setSumUnderPutAway(result?.reduce((sumUnderPutAway, currentItem) => sumUnderPutAway = sumUnderPutAway + parseFloat(currentItem.underPutaway), 0));
            }
        });
    }, [homeState.catalogue?.selectedParty]);


    useEffect(() => {
        articleService.getArticle(homeState.catalogue.selectedSite, homeState.catalogue?.selectedParty).then(function (result) {
            dispatch(ArtclActionCreators.landing([]));
            dispatch(ArtclActionCreators.artclOptions([]));
            if (result) {
                dispatch(ArtclActionCreators.landing(result));
                const ArticleData = result.map((data, i) => { return { value: data.articleNumber, label: data.articleShortDescription } });
                dispatch(ArtclActionCreators.artclOptions(ArticleData));
            }
        });
    }, [homeState.catalogue?.selectedParty]);

    function handleChange(event) {
        //debugger;
        dispatch(ActionCreators.filterBrand(event.label));
        setState(prevValue => {
            return {
                ...state,
                homeState: {
                    catalogue: {
                        ...state.homeState.catalogue,
                        selectedBrand: event.label
                    }
                }
            };
        });
        dispatch(ActionCreators.filterStockByArticle(homeState.catalogue.catalogue));
        if (!articleOptions) {

            articleFilterStockByBrand([], event.value);
        }
        else {
            articleFilterStockByBrand(articleOptions?.optionSelected, event.value);
        }


    }

    function storeHandleChange(event) {
        setSelectedStore(event.value);
        if (!articleOptions) {

            articleFilterStockByStore([], event.value);
        }
        else {
            articleFilterStockByStore(articleOptions?.optionSelected, event.value);
        }


    }

    const brands = homeState.catalogue.masterkeys[0]?.items;

    function multiselectChange(selected) {
        debugger;
        dispatch(ActionCreators.filterStockByArticle(homeState.catalogue.catalogue));
        if (homeState.catalogue?.selectedParty != "AFIKEAFB")
            articleFilterStockByBrand(selected, homeState.catalogue.selectedBrand);

        if (homeState.catalogue?.selectedParty == "AFIKEAFB")
            articleFilterStockByStore(selected, selectedStore)
    };

    function articleFilterStockByBrand(selected, BrandName) {


        let filterArticles = homeState.catalogue.catalogue;

        if (BrandName && BrandName !== "" && BrandName !== "None" && BrandName !== "All") {

            filterArticles = filterArticles.filter(
                (data) => {
                    if (data.brand && data.brand === BrandName)
                        return data;
                    else
                        return null;
                });


        }

        let yFilter = [];

        if (!selected)
            selected = [];

        if (selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === "All"
        ) {
            yFilter = homeState.article.articleOptions?.map(item => { return item.value; });

            setArticleOptions({
                optionSelected: homeState.article.articleOptions
            });
        }
        else {
            yFilter = selected?.map(item => { return item.value; });

            setArticleOptions(prevValue => {
                return {
                    ...prevValue,
                    optionSelected: selected
                };
            });
        }
        console.log('yFilter');
        console.log(yFilter);
        if (yFilter && yFilter.length > 0) {

            filterArticles = filterArticles.filter(item => yFilter?.includes(item.articleCode));
        }

        setSumInStock(filterArticles?.reduce((sumInStock, currentItem) => sumInStock = sumInStock + parseFloat(currentItem.totalQuantity), 0));
        setSumAvailable(filterArticles?.reduce((sumAvailable, currentItem) => sumAvailable = sumAvailable + parseFloat(currentItem.scannedQuantity), 0));
        setSumUnderPicking(filterArticles?.reduce((sumUnderPicking, currentItem) => sumUnderPicking = sumUnderPicking + parseFloat(currentItem.underPicking), 0));
        setSumUnderPutAway(filterArticles?.reduce((sumUnderPutAway, currentItem) => sumUnderPutAway = sumUnderPutAway + parseFloat(currentItem.underPutaway), 0));

        dispatch(ActionCreators.filterStockByArticle(filterArticles));

    }

    function articleFilterStockByStore(selected, storeName) {


        let filterArticles = homeState.catalogue.catalogue;

        if (storeName !== "" && storeName !== "None" && storeName !== "All") {

            filterArticles = filterArticles.filter(
                (data) => {
                    if (data.store && data.store === storeName)
                        return data;
                    else
                        return null;
                });


        }

        let yFilter = [];

        if (!selected)
            selected = [];

        if (selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === "All"
        ) {
            yFilter = homeState.article.articleOptions?.map(item => { return item.value; });

            setArticleOptions({
                optionSelected: homeState.article.articleOptions
            });
        }
        else {
            yFilter = selected?.map(item => { return item.value; });

            setArticleOptions(prevValue => {
                return {
                    ...prevValue,
                    optionSelected: selected
                };
            });
        }

        if (yFilter && yFilter.length > 0) {

            filterArticles = filterArticles.filter(item => yFilter?.includes(item.articleCode));
        }

        setSumInStock(filterArticles?.reduce((sumInStock, currentItem) => sumInStock = sumInStock + parseFloat(currentItem.totalQuantity), 0));
        setSumAvailable(filterArticles?.reduce((sumAvailable, currentItem) => sumAvailable = sumAvailable + parseFloat(currentItem.scannedQuantity), 0));
        setSumUnderPicking(filterArticles?.reduce((sumUnderPicking, currentItem) => sumUnderPicking = sumUnderPicking + parseFloat(currentItem.underPicking), 0));
        setSumUnderPutAway(filterArticles?.reduce((sumUnderPutAway, currentItem) => sumUnderPutAway = sumUnderPutAway + parseFloat(currentItem.underPutaway), 0));

        dispatch(ActionCreators.filterStockByArticle(filterArticles));

    }

    const multiDataSet = [
        {
            columns: [
                {title:"Warehouse Number", width: {wpx: 80}},
                {title:"{homeState.catalogue.filterCatalogue?.[0]?.externalCustomerName}", width: {wpx: 80}},
                {title:"Brand", width: {wpx: 80}},
                {title:"Article", width: {wpx: 80}},
                {title:"Barcode", width: {wpx: 80}},
                {title:"Article Description", width: {wpx: 80}},
                {title:"Unit Of Measure", width: {wpx: 80}},
                {title:"In Stock", width: {wpx: 80}},
                {title:"Available", width: {wpx: 80}},
                {title:"Under Picking", width: {wpx: 80}},
                {title:"Under Putaway", width: {wpx: 80}},
                {title:"Stock Status", width: {wpx: 80}},
                {title:"Stock Type Desc", width: {wpx: 80}},
                {title:"Production Date", width: {wpx: 80}},
                {title:"Expiry Date", width: {wpx: 80}},
                {title:"Remaining Shelf Life", width: {wpx: 80}},
                {title:"Per Carton", width: {wpx: 80}},
                {title:"Volume", width: {wpx: 80}},
                {title:"Volume Unit", width: {wpx: 80}},
                {title:"Vintage", width: {wpx: 80}},
                {title:"Batch #", width: {wpx: 80}},
                {title:"LOT #", width: {wpx: 80}},
                {title:"Serial #", width: {wpx: 80}},
                {title:"FIRS Number", width: {wpx: 80}},
                {title:"Country Of Origin", width: {wpx: 80}},
                {title:"stockTypeDescription", width: {wpx: 80}},
                {title:"GRN Date", width: {wpx: 80}},
                {title:"Store", width: {wpx: 80}},
            ],
            data: 
                homeState.catalogue.filterCatalogue?.map((l, i) =>
                    [
                        {value:l.siteCode},
                        {value:l.externalCustomerName},
                        {value:l.brand},
                        {value:l.articleCode},
                        {value:l.articleBarcode},
                        {value:l.articleDescription},
                        {value:l.unitOfMeasure},
                        {value:l.totalQuantity},
                        {value:l.scannedQuantity},
                        {value:l.underPicking},
                        {value:l.underPutaway},
                        {value:l.stockType},
                        {value:l.stockTypeDescription},
                        {value:l.productionDate},
                        {value:l.expiryDate},
                        {value:l.remainingSelfLife, style: {fill: {patternType: "solid", fgColor: {rgb: parseInt(l.remainingSelfLife.trim())<90 ? "FFFF0000" : parseInt(l.remainingSelfLife.trim())>=90 && parseInt(l.remainingSelfLife.trim())<=120 ? "FFFFFF00": "FF00FF00" }}}},
                        {value:l.perCarton},
                        {value:l.volume},
                        {value:l.volumeUnit},
                        {value:l.vintage},
                        {value:l.articleBatchNumber},
                        {value:l.lotNumber},
                        {value:l.serialNumber},
                        {value:l.foodInspectionReportNumber},
                        {value:l.countryOfOrigin},
                        {value:l.stockTypeDescription},
                        {value:l.goodsReceiptDate},
                        {value:l.store},
                    ],                
                )            
            
        }
    ];

    return (
        <>
            <div className="row searchControl">
                <div className="col-md-4">
                    <ReactSelect
                        options={homeState.article.articleOptions}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                            Option, MultiValue
                        }}
                        onChange={multiselectChange}
                        allowSelectAll={true}
                        value={articleOptions.optionSelected}
                    />
                </div>
                <div className={homeState.article.masterBrand ? "col-md-3" : "hidden"}>
                    <Select styles={colourStyles} onChange={handleChange} options={homeState.article.masterBrand} placeholder="Select Brand" />
                </div>

                <div className={homeState.catalogue?.selectedParty == "AFIKEAFB" ? "col-md-3" : "hidden"}>
                    <Select styles={colourStyles} onChange={storeHandleChange} options={storeMaster} placeholder="Select Store" />
                </div>
                <div className={homeState.catalogue?.selectedParty == "AFIKEAFB" || homeState.article.masterBrand ? "hidden" : "col-md-3"}>
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-2 float-right">
                    <ExportExcel data={multiDataSet}></ExportExcel>
                </div>
            </div>
            <br></br>
            <div className="row divContent">
                <div className="col-md-12">

                    <Table striped bordered hover className="table sticky-table">
                        <thead className="stickyHead">
                            <tr>
                                <th>Warehouse Number</th>
                                <th>{homeState.catalogue.filterCatalogue?.[0]?.externalCustomerName}</th>
                                <th>Brand</th>
                                <th>Article</th>
                                <th>Barcode</th>
                                <th>Article Description</th>
                                <th>Unit Of Measure</th>
                                <th>In Stock</th>
                                <th>Available</th>
                                <th>Under Picking</th>
                                <th>Under Putaway</th>
                                {/*<th>Stock Status</th>*/}
                                <th>Stock Type Desc</th>
                                <th>Production Date</th>
                                <th>Expiry Date</th>
                                <th>Remaining Shelf Life</th>
                                <th>Per Carton</th>
                                <th>Volume</th>
                                <th>Volume Unit</th>
                                <th>Vintage</th>
                                <th>Batch #</th>
                                <th>LOT #</th>
                                <th>Serial #</th>
                                <th>FIRS Number</th>
                                <th>Country Of Origin</th>
                                {/* <th>stockTypeDescription</th> */}
                                <th>GRN Date</th>
                                <th>Store</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homeState.catalogue.filterCatalogue?.map((l, i) =>

                                <tr key={i} >
                                    <td>{l.siteCode}</td>
                                    <td>{l.externalCustomerName}</td>
                                    <td>{l.brand}</td>
                                    <td>{l.articleCode}</td>
                                    <td>{l.articleBarcode}</td>
                                    <td>{l.articleDescription}</td>
                                    <td>{l.unitOfMeasure}</td>
                                    <td>{l.totalQuantity}</td>
                                    <td>{l.scannedQuantity}</td>
                                    <td>{l.underPicking}</td>
                                    <td>{l.underPutaway}</td>
                                    {/*<td>{l.stockType}</td>*/}
                                    <td>{l.stockTypeDescription}</td>
                                    <td>{l.productionDate}</td>
                                    <td>{l.expiryDate}</td>
                                    <td className={parseInt(l.remainingSelfLife.trim())<90? "tdRed" : parseInt(l.remainingSelfLife.trim())>=90 && parseInt(l.remainingSelfLife.trim())<=120? "tdYellow": "tdGreen"}><b>{l.remainingSelfLife}</b></td> 
                                    <td>{l.perCarton}</td>
                                    <td>{l.volume}</td>
                                    <td>{l.volumeUnit}</td>
                                    <td>{l.vintage}</td>
                                    <td>{l.articleBatchNumber}</td>
                                    <td>{l.lotNumber}</td>
                                    <td>{l.serialNumber}</td>
                                    <td>{l.foodInspectionReportNumber}</td>
                                    <td>{l.countryOfOrigin}</td>
                                    {/* <td>{l.stockTypeDescription}</td> */}
                                    <td>{l.goodsReceiptDate}</td>
                                    <td>{l.store}</td>
                                </tr>
                            )}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{sumInStock && sumInStock!=""? parseInt(sumInStock).toLocaleString('en-US') : ""}</td>
                                <td>{sumAvailable && sumInStock!=""? parseInt(sumAvailable).toLocaleString('en-US') : "" }</td>
                                <td>{sumUnderPicking}</td>
                                <td>{sumUnderPutAway}</td>
                                {/*<td>{l.stockType}</td>*/}
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {/* <td>{l.stockTypeDescription}</td> */}
                                <td></td>
                                <td></td>
                            </tr>

                        </tbody>
                    </Table>

                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    //debugger;
    //console.log('home mapStateToProps - ');
    //console.log(state);
    return { homeState: state }
}

export default connect(mapStateToProps)(Home);