import React, { useState, useEffect } from 'react';
import Navigation from '../../navigation';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../context/authConfig'
import { connect, useDispatch } from 'react-redux';
import { LoginActionCreators } from '../../actions/loginAction'
import userService from '../../apiLogistics/userService'


function UserLogin({ currentState }) {
    const dispatch = useDispatch();
    const { instance, accounts } = useMsal();
    const [userLoginState, setUserLoginState] = useState(false);
    //console.log('accountspppppppppppp');
    //console.log(accounts);
    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    useEffect(() => {
        if (!currentState.userProfile?.userprofileData) {
            userService.getUserData(accounts[0].username).then(function (result) {    
                result.homeAccountId = accounts[0].homeAccountId;       
                dispatch(LoginActionCreators.userprofiledata(result));
                setUserLoginState(true);
            });
        }
    }, []);

    /*     useEffect(() => {
    
            if (window.localStorage.getItem('state') !== null) {
                        setUserLoginState(JSON.parse(window.localStorage.getItem('state')));
                        dispatch(LoginActionCreators.updateuserprofile(userLoginState));
                    } 
    
            if (!userLoginState?.isUserVerified) {
                debugger;
                instance.acquireTokenSilent(request).then((response) => {
                    dispatch(LoginActionCreators.userprofile(response));
                    userService.getUserData(accounts[0].username).then(function (result) {
                        dispatch(LoginActionCreators.userprofiledata(result));
                    });
                }).catch((e) => {
                    console.log(e);
                });
            }
    
            if (currentState.userProfile?.userprofileData?.status === "Active")
                return (
                    <>
                        <Navigation></Navigation>
                    </>
                );
            else {
                return "User dont have access to application. Can you please contact to IT team.";
            }
    
        }, []); */

    if (currentState.userProfile?.userprofileData?.status === "Active")
        return (
            <>
                <Navigation userprofileData={currentState.userProfile?.userprofileData} ></Navigation>
            </>
        );
    else if (userLoginState) {
        return "User dont have access to application. Can you please contact to IT team.";
    }
    else
        return "Loading .. .. .. ..";

}

const mapStateToProps = state => {
    console.log('User login state');
    console.log(state);
    return { currentState: state }
}
export default connect(mapStateToProps)(UserLogin);
//export default (UserLogin);