import { ProfileTypes } from '../constants/ActionType';

const initialState = {
  loginUserEmail: "",
  loginUserToken: "",
  isUserVerified: false,
  userData: []
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProfileTypes.USERPROFILE:
      let currentState = {
        ...state,
        userData: action.payload.data,
        loginUserEmail: action.payload.data.account.username,
        loginUserToken: action.payload.data.idToken,
        isUserVerified: true
      }
      window.localStorage.setItem('state', JSON.stringify(currentState));
      return currentState;
    case ProfileTypes.UPDATEUSERPROFILE:
      return action.payload.data;
    case ProfileTypes.USERPROFILEDATA:
      return {
        ...state,
        isUserVerified: true,
        userprofileData: action.payload.data
      };
    default:
      return state;
  }
}

export default profileReducer;