import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducer from '../reducers/reducer';
import articleReducer from '../reducers/articleReducer';
import profileReducer from '../reducers/loginReducer';

const rootReducer = combineReducers({
  catalogue: reducer,
  article: articleReducer,
  userProfile:profileReducer
});

const configureStore = () => { 
  return createStore(
    rootReducer,
    compose(applyMiddleware(thunk))
  );
};

export default configureStore;