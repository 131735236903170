import { viewarticleTypes } from '../constants/ActionType';

const initialState = {
    article: [],
    filterArticle: [],
    articleOptions: [],
    masterBrand:[],
    selectedSite: '',
    selectedParty: '',
    authToken: '',
    loginSuccessful: false
}

const articleReducer = (state = initialState, action) => {
    //debugger;
    switch (action.type) {
        case viewarticleTypes.LANDING:
            //console.log('articleData', action.payload.articleData);
            //console.log(action.payload.articleData);
            return {
                ...state,
                article: action.payload.articleData,
                filterArticle: action.payload.articleData,
                formSubmitted: false // after update user formsubmition reset
            }
        case viewarticleTypes.TEXTFILTER:
            //console.log('filter Article');
            //console.log(action.payload.data);
            return {
                ...state,
                filterArticle: action.payload.data,
                formSubmitted: false // after update user formsubmition reset
            }
        case viewarticleTypes.ARTICLEOPTIONS:
            //console.log('articleData', action.payload.artclOptionsData);
            //console.log(action.payload.artclOptionsData);

            const res = [{ value: 'All', label: 'All' }, ...action.payload.artclOptionsData];

            return {
                ...state,
                articleOptions: res,
                formSubmitted: false // after update user formsubmition reset
            }
        case viewarticleTypes.SELECTEDSITE:
            //console.log('Filter Data ==', state)
            return {
                ...state,
                selectedSite: action.payload.selectedSite,
                formSubmitted: false // after update user formsubmition reset
            }
        case viewarticleTypes.SELECTEDPARTY:
            //console.log('Filter Data ==', state)
            return {
                ...state,
                selectedParty: action.payload.selectedParty,
                formSubmitted: false // after update user formsubmition reset
            }
        case viewarticleTypes.MASTERBRAND:
            return {
                ...state,
                masterBrand: action.payload.data,
                formSubmitted: false // after update user formsubmition reset
            }
        default:
            return state;
    }
}

export default articleReducer;
