import React from 'react'

class ToggleSidebar extends React.Component {

    constructor(props) {

        super(props);
        console.log('ToggleSidebar');
        console.log(props);

    }

    render() {

        if (this.props.userprofileData.type == "admin") {

            return (
                <div>
                    <div className="sidebar-container">
                        <ul className="sidebar-navigation">
                            <li className="header">Catalogue</li>
                            <li className="navSubMenu">
                                <a href="/home">
                                    <i className="fa fa-home" aria-hidden="true" /> Search Catalogue
                                </a>
                            </li>
                            {/*  <li className="navSubMenu">
                            <a href="#">
                                <i className="fa fa-tachometer" aria-hidden="true" /> Dashboard
                            </a>
                        </li> */}
                            <li className="header">View Stock Status</li>
                            <li className="navSubMenu">
                                <a href="/article">
                                    <i className="fa fa-users" aria-hidden="true" /> Article Stock
                                </a>
                            </li>

                            <li className="header">User Management</li>
                            <li className="navSubMenu">
                                <a href="/userList">
                                    <i className="fa fa-users" aria-hidden="true" /> User List
                                </a>
                            </li>
                            <li className="navSubMenu">
                                <a href="/userManagement">
                                    <i className="fa fa-users" aria-hidden="true" /> Update User Details
                                </a>
                            </li>
                            {/* <li className="header">Account Management</li>
                        <li className="navSubMenu">
                            <a href="#">
                                <i className="fa fa-cog" aria-hidden="true" /> Settings
                            </a>
                        </li>
                        <li className="navSubMenu">
                            <a href="#">
                                <i className="fa fa-info-circle" aria-hidden="true" /> Help
                            </a>
                        </li> */}
                        </ul>
                    </div>

                </div>
            )
        }
        else {
            return (
                <div>
                    <div className="sidebar-container">
                        <ul className="sidebar-navigation">
                            <li className="header">Catalogue</li>
                            <li className="navSubMenu">
                                <a href="/home">
                                    <i className="fa fa-home" aria-hidden="true" /> Search Catalogue
                                </a>
                            </li>
                            <li className="header">View Stock Status</li>
                            <li className="navSubMenu">
                                <a href="/article">
                                    <i className="fa fa-users" aria-hidden="true" /> Article Stock
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            )
        }
    }
}

export default ToggleSidebar;