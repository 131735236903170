import { LogLevel } from "@azure/msal-browser";
import config from '../context/config'

export const b2cPolicies = {
    names: {
        signUpSignIn: config.getSignUpSignInPolicy(),
        // forgotPassword: "B2C_1_app_fpass",
        //editProfile: "B2C_1_app_pedit"
    },
    authorities: {
        signUpSignIn: {
            authority: config.getAuthority(),
        },
        /*  forgotPassword: {
             authority: "https://alfuttaimb2c.b2clogin.com/alfuttaimb2c.onmicrosoft.com/b2c_1_app_fpass",
         },
         editProfile: {
             authority: "https://alfuttaimb2c.b2clogin.com/alfuttaimb2c.onmicrosoft.com/B2C_1_app_pedit"
         } */
    },
    tenantUrl: config.getTenantUrl(),
    authorityDomain: config.getAuthorityDomain()
}

export const msalConfig = {
    auth: {
        clientId: config.getClientId(), // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: config.getRedirectUri(),
        postLogoutRedirectUri: config.getPostLogoutRedirectUri(),
        navigateToLoginRequestUrl: config.getNavigateToLoginRequestUrl(), // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: config.getADCache(),
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    clientId: config.getClientId(),
    authority: config.getAuthority(),
    redirectUri: config.getRedirectUri(),
    scopes: config.getScopes()
};

export const SignupRequest = {
    clientId: config.getClientId(),
    authority: config.getSignupAuthority(),
    scopes: config.getScopes(),
    //redirectUri:config.getRedirectUri()
};


export const azureADConfigAdmin = {
    auth: {
        clientId: config.getClientId(),
        authority: config.getAdminAuthority(),
        redirectUri: config.getRedirectUri()
    },
    cache: config.getADCache()
}