import { HomeTypes } from '../constants/ActionType';

export const ActionCreators = {
  home: (homeData) => ({ type: HomeTypes.HOME, payload: { homeData } }),
  catalogue: (catalogueData) => ({ type: HomeTypes.CATALOGUE, payload: { catalogueData } }),
  filterSite: (selectedSite) => ({ type: HomeTypes.SELECTEDSITE, payload: { selectedSite } }),
  filterParty: (selectedParty) => ({ type: HomeTypes.SELECTEDPARTY, payload: { selectedParty } }),
  filterBrand: (selectedBrand) => ({ type: HomeTypes.SELECTEDBRAND, payload: { selectedBrand } }),
  filterText: (selectedText) => ({ type: HomeTypes.SEARCHTEXT, payload: { selectedText } }),
  filterStockByBrand: (data) => ({ type: HomeTypes.BRANDFILTER, payload: { data } }),
  masterKeys: (data) => ({ type: HomeTypes.MASTERKEY, payload: { data } }),
  filterStockByArticle: (data) => ({ type: HomeTypes.ARTICLEFILTER, payload: { data } })
}