import React from 'react'
import axios from 'axios';
import config from '../context/config'

const ARTICLE_API_BASE_URL = config.getLogisticsAPIUrl();

const optionAxios = {
    headers: {
        'Content-Type': 'application/json'
    }
}

class userService extends React.Component {

    async getUserData(usermail) {
        return await axios.get(ARTICLE_API_BASE_URL + 'verifyUserDetails?userEmail=' + usermail
            , {})
            .then((res) => {
                console.log('userService');
                console.log(res.data)
                return res.data;
            })
            .catch((error) => {
                console.error(error)
            })
    }

    async getAllUserDetails() {
        //debugger;
        return await axios.get(ARTICLE_API_BASE_URL + 'getAllUserDetails'
            , {})
            .then((res) => {
                console.log('userService');
                console.log(res.data)
                return res.data;
            })
            .catch((error) => {
                console.error(error)
            })
    }

    async createNewUser(userData) {
        
        var config = {
            method: 'post',
            url: ARTICLE_API_BASE_URL + 'createNewUser',
            headers: {
                'Content-Type': 'application/json'
            },
            data: userData
        };

        return await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

        /* return await axios.post(ARTICLE_API_BASE_URL + 'createNewUser', userData, optionAxios)
            .then((res) => {
                console.log('userService');
                console.log(res.data)
                return res.data;
            })
            .catch((error) => {
                console.error(error)
            }) */
    }

    async updateUser(userData) {
        return await axios.post(ARTICLE_API_BASE_URL + 'updateUser', userData, optionAxios)
            .then((res) => {
                console.log('userService');
                console.log(res.data)
                return res.data;
            })
            .catch((error) => {
                console.error(error)
            })
    }
}

export default new userService()