import React from 'react'
import axios from 'axios';
import config from '../context/config'

//const CATALOGUE_API_BASE_URL = "https://aedevapigw-internal.corp.al-futtaim.com/dev/logistics/warehouse-operations/v1/inventories?siteCode=AFL1&externalCustomerName=LEBJAMN";
//const CATALOGUE_API_BASE_URL = "https://aedevapigw-internal.corp.al-futtaim.com/dev/logistics/warehouse-operations/v1/inventories?";
const CATALOGUE_API_BASE_URL = config.getLogisticsAPIUrl();

class CatalogueService extends React.Component {

    async getCatalogue(siteCode, externalCustomerName) {
        //this.state = useSelector((state) => state)

        //console.log('1' + this.state);

        return await axios.get(CATALOGUE_API_BASE_URL + 'articlestock?siteCode=' + siteCode + '&externalCustomerName=' + externalCustomerName//LEBJAMN
            , {
                /*  headers: {
                     'Connection': 'keep-alive',
                     withCredentials: false,
                 } */
            })
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error(error)
            })
    }

    async articleMasterKeys() {
        return await axios.get(CATALOGUE_API_BASE_URL + 'articleMasterKeys'
            , {})
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.error(error)
            })
    }

    createCatalogue(Catalogue) {
        //return axios.post(CATALOGUE_API_BASE_URL, Catalogue);

        const username = ''
        const password = ''

        const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

        const url = CATALOGUE_API_BASE_URL
        const data = {}

        return axios.post(url, data, {
            headers: {
                'Authorization': `Basic ${token}`
            },
        })
    }

    getCatalogueId(CatalogueId) {
        return axios.get(CATALOGUE_API_BASE_URL + '/' + CatalogueId);
    }

    updateCatalogue(Catalogue, CatalogueId) {
        return axios.put(CATALOGUE_API_BASE_URL + '/' + CatalogueId, Catalogue);
    }

    deleteV(CatalogueId) {
        return axios.delete(CATALOGUE_API_BASE_URL + '/' + CatalogueId);
    }
}

export default new CatalogueService()