import React, { useState, useReducer, useEffect } from 'react'
import { connect, useSelector, useDispatch, useRef } from 'react-redux';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import articleService from '../../apiLogistics/articleService'
import { ActionCreators } from '../../actions/homeAction'
import { ArtclActionCreators } from '../../actions/articleAction'
import { useMsal } from "@azure/msal-react";

function Header({ headerState }) {

  const dispatch = useDispatch();
  const [state, setState] = useState({ headerState });
  const sites = headerState?.userProfile?.userprofileData?.sites;//headerState.masterKeys?.filter(s => s.id = 'Site').items;
  const [parties, setParties] = useState([]);

  /* useEffect(() => {
    CatalogueService.getCatalogue(headerState?.userProfile?.userprofileData?.site, headerState?.userProfile?.userprofileData?.party).then(function (result) {
      dispatch(ActionCreators.catalogue(null))
      if (result)
        dispatch(ActionCreators.catalogue(result));
    });

    articleService.getArticle(headerState?.userProfile?.userprofileData?.site, headerState?.userProfile?.userprofileData?.party).then(function (result) {
      dispatch(ArtclActionCreators.landing([]));
      dispatch(ArtclActionCreators.artclOptions([]));
      if (result) {
        dispatch(ArtclActionCreators.landing(result));
        const ArticleData = result.map((data, i) => { return { value: data.articleNumber, label: data.articleNumber } });
        dispatch(ArtclActionCreators.artclOptions(ArticleData));
      }
    });
  }, [headerState?.userProfile?.userprofileData]); */

  function signOutClickHandler(instance) {
    console.log(headerState);
    const logoutRequest = {
      account: instance.getAccountByHomeId(headerState.userProfile.userprofileData.homeAccountId),
      postLogoutRedirectUri: articleService.userSessionLogout()
    }
    instance.logoutRedirect(logoutRequest);
  }

  function SignOutButton() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();

    //return <button onClick={() => signOutClickHandler(instance)}>Sign Out</button>

    return <NavDropdown.Item onClick={() => signOutClickHandler(instance)}>Logout</NavDropdown.Item>
  };


  function handleChange(event) {
    //debugger;
    dispatch(ActionCreators.filterSite(event.label));
    dispatch(ArtclActionCreators.filterSite(event.label));

    setParties(headerState?.userProfile?.userprofileData?.sites.filter(option =>
      option.label === event.label)[0].children);

    /* CatalogueService.getCatalogue(event.label, headerState.catalogue.selectedParty).then(function (result) {
      dispatch(ActionCreators.catalogue(null))
      if (result)
        dispatch(ActionCreators.catalogue(result));
    });

    articleService.getArticle(event.label, headerState.catalogue.selectedParty).then(function (result) {
      dispatch(ArtclActionCreators.landing([]));
      dispatch(ArtclActionCreators.artclOptions([]));
      if (result) {
        dispatch(ArtclActionCreators.landing(result));
        const ArticleData = result.map((data, i) => { return { value: data.articleNumber, label: data.articleShortDescription } });
        dispatch(ArtclActionCreators.artclOptions(ArticleData));
      }
    }); */



    setState(prevValue => {
      return {
        ...state,
        headerState: {
          catalogue: {
            ...state.headerState.catalogue,
            selectedSite: event.label
          }
        }
      };
    });

  };

  function partyHandleChange(event) {
    //debugger;
    dispatch(ActionCreators.filterParty(event?.label));
    dispatch(ArtclActionCreators.filterParty(event?.label));

    dispatch(ArtclActionCreators.masterBrand(parties?.filter(option =>
      option.label === event.label)[0].children));

    setState(prevValue => {
      return {
        ...state,
        headerState: {
          catalogue: {
            ...state.headerState.catalogue,
            selectedParty: event.label
          }
        }
      };
    });
  };


  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        color: "black"
      };
    }
  };

  return (
    <div className="col-md-12">
      <React.Fragment>
        <Router>
          <React.Fragment>
            {/*  <MenuToggleButton></MenuToggleButton> */}
            <Navbar className="topbar-logo" expand="lg" sticky="top">
              <div className="sidebar-logo">
                AL FUTTAIM LOGISTICS
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  {/* <Nav.Link href="/">Search Catalogue</Nav.Link>
                  <Nav.Link href="/article">Article Stock</Nav.Link> */}
                  {/* <Nav.Link href="/contact-us">About Us</Nav.Link> */}
                </Nav>
                {/*  <Form inline>
                      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                      <Button variant="outline-success">Search</Button>
                    </Form> */}
              </Navbar.Collapse>
              <div className="col-md-4">
                <div className="row">
                  {/*  <div className="col-md-6"></div> */}
                  <div className="col-md-6" >
                    <Select name="comboSite" onChange={handleChange} options={sites} styles={colourStyles} placeholder="Select Site" />
                  </div>
                  <div className="col-md-6">
                    <Select name="comboParty" onChange={partyHandleChange} options={parties} styles={colourStyles} placeholder="Select Party" />
                  </div>
                </div>
              </div>
              <NavDropdown title={headerState?.userProfile?.userprofileData?.fullname} id="basic-nav-dropdown" className="ml-auto navitemdropdown">
                <NavDropdown.Item href="#action/3.1">Settings</NavDropdown.Item>
                {/*  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item> */}
                <NavDropdown.Divider />
                <SignOutButton />
              </NavDropdown>
            </Navbar>
          </React.Fragment>
        </Router>
      </React.Fragment>
    </div>
  )

}

const mapStateToProps = state => {
  return { headerState: state }
}


//export default Header;

export default connect(mapStateToProps)(Header);