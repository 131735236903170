import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import './style.css'
import Header from '../components/header';
import ToggleSidebar from '../components/ToggleSidebar';
import CatalogueService from '../apiLogistics/CatalogueService';
import Home from "../screens/logistics/Home";
import Viewarticle from "../screens/logistics/viewarticle";
import UserManagement from "../screens/logistics/userManagement";
import UserList from '../screens/logistics/UserList';


class Navigation extends Component {
  constructor(props) {

    super(props);
    console.log('class Navigation');
    console.log(props);

  }
  render() {
    return (
      <div className="row">
        <Header></Header>
        <div className="col-md-2">
          <ToggleSidebar userprofileData={this.props.userprofileData} />
        </div>
        <div className="col-md-10">
          <div className="row pageContent">
            <Router >
              <React.Fragment>
                <Switch>
                  <Route exact path="/">
                    <Viewarticle />
                  </Route>
                  <Route exact path="/article">
                    <Home />
                  </Route>
                  <Route path="/home">
                    <Viewarticle />
                  </Route>
                  <Route path="/userManagement">
                    <UserManagement />
                  </Route>
                  <Route path="/userList">
                    <UserList />
                  </Route>
                </Switch>
              </React.Fragment>
            </Router>
          </div>
        </div>
      </div>
    )
  }
}


export default Navigation;
