import React, { useEffect, useState, Component } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalConfig, azureADConfigAdmin, loginRequest, SignupRequest } from "./context/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import UserLogin from './components/userlogin/UserLogin'

const isAdminLogin = window.location.href.includes("/home")
const msalInstance = new PublicClientApplication(isAdminLogin ? azureADConfigAdmin : msalConfig);
function SignInButton() {
  const { instance } = useMsal();
  //instance.loginRedirect(loginRequest)
  return (
     <div>
       <button  id="btnLogin" onClick={() => instance.loginRedirect(loginRequest)}>Login to Logistics</button>
     </div>
   )
}

function SignUpButton() {
  const { instance } = useMsal();
  //instance.loginRedirect(loginRequest)
  return (
     <div>
       <button  id="btnLogin" onClick={() => instance.loginRedirect(SignupRequest)}>SignUp to Logistics</button>
     </div>
   )
}

function App() {
  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <UserLogin />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <p>No users are signed in!</p>
          <SignInButton />
          <SignUpButton></SignUpButton>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </React.Fragment>
  );
}

export default App;
